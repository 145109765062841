@import '~packages/components/styles/export';

.middle-content {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.screen-lg-min({ .middle-content {
    margin-left: auto;
    margin-right: auto;
} });

.main-wrapper {
  position: relative;
  padding-bottom: 75px;
}

.screen-sm-min({ .main-wrapper {
    padding-bottom: 75px;
} });
.screen-md-min({ .main-wrapper {
    min-height: 400px;
    padding-left: 115px;
    padding-bottom: 0;
} });
.screen-lg-min({ .main-wrapper {
    padding-left: 170px;
    min-height: 500px;
} });

.reactRoot,
.main-content {
  background: #f6f6f6;
  height: auto;
  box-sizing: border-box;
}

.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
