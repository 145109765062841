@import '../common/style.less';

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: #000;
  opacity: 0.5;
  z-index: 99;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;

  width: 700px;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  line-height: 1.5;

  transform: translate(-50%, -50%);

  &__header {
    text-align: center;
    padding: 20px 50px;
  }
  &__title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }
  &__body {
    text-align: center;
    padding: 20px 50px;
    font-size: 16px;
  }
  &__footer {
    display: flex;
    justify-content: center;
    padding: 20px 50px;
  }
  &__button {
    text-align: center;
    width: 30%;
    & + & {
      margin-left: 20px !important;
    }
  }
}
