.ui.form {
  & input:not([type]),
  & input[type='date'],
  & input[type='datetime-local'],
  & input[type='email'],
  & input[type='number'],
  & input[type='password'],
  & input[type='search'],
  & input[type='tel'],
  & input[type='time'],
  & input[type='text'],
  & input[type='file'],
  & input[type='url'],
  & textarea {
    font-family: @fontFamily, Arial, Helvetica, sans-serif;
    line-height: 1.21428571em;
    padding: 9px;
    font-size: 15px;
    border: 1px solid #d0d1d2;
    color: #131516;
    border-radius: 2px;
    box-shadow: none;
    resize: none;

    &.fill-vertical {
      height: 100%;
    }
  }

  .form__outer {
    margin: 10px -21px;
    padding: 10px 21px 20px;
    background-color: #f4f4f4;

    .inline.fields {
      margin: 0;
    }
  }

  & .field {
    margin-top: 10px;
    margin-bottom: 0;

    &.field--n-top-ind {
      margin-top: 0;
    }
  }
  & .fields {
    margin-top: -10px;
    margin-bottom: 0;

    & > .field {
      margin-top: 10px !important;
      margin-bottom: 0 !important;
      display: flex;
    }
  }

  .ui.selection.dropdown {
    min-width: 13em;
    min-height: 38px;
    padding: 9px;

    border: 1px solid #d0d1d2;

    font-size: 15px;
    line-height: 1.21428571em;
    color: #131516;
    font-family: @fontFamily, Arial, Helvetica, sans-serif;

    border-radius: 2px;
    box-shadow: none;
  }
}
