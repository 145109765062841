.icon {
  &--zoom {
    position: absolute;
    top: 50%;
    left: 7px;
    margin-top: -7px;

    .path-1 {
      fill: #fff;
    }
  }
  &.icon--format-portrait,
  &.icon--format-portrait-white {
    width: 13px;
    height: 22px;
    margin-left: 54px;
  }
  &.icon--format-landscape {
    width: 22px;
    height: 13px;
    margin-left: 35px;
  }
  &.icon--format-portrait,
  &.icon--format-landscape,
  &.icon--format-portrait-white {
    display: inline-block;
    vertical-align: middle;
    background-color: #ccc;
  }
  &.icon--format-portrait-white {
    background-color: #fff;
  }
}
