.main-content {
  padding-top: @header-height;

  &.with-padding {
    background-color: white;
    padding: (@header-height + 20) 20px 20px;
  }

  &-topbar {
    margin-bottom: 20px;
  }
}
.screen-sm-min({
  .main-content {
    &-topbar {
      position: relative;
    }
  }
});

.pictures-grid {
  list-style: none;
  display: flex;
  flex-flow: row;
  align-items: stretch;

  margin-left: -5px;
  margin-right: -5px;

  &__item {
    flex: auto;
    padding: 5px;
    position: relative;
    overflow: hidden;

    &:hover {
      .pictures-grid__actions {
        transform: translateY(-5px);
      }
      .pictures-grid__cover {
        display: none;
      }
    }

    &.pictures-grid__item--has-rating:hover {
      .pictures-grid__cover {
        display: block !important;
        z-index: 4;
      }
    }
  }
  &__image {
    display: block;
    max-width: 100%;
    max-height: 400px;
    width: 100%;
    height: 100%;
    &-placeholder {
      background-color: #ccc;
      max-height: 100%;
    }
  }
  &__actions {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 0;
    z-index: 3;

    height: 50px;

    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);

    transform: translateY(50px);
    transition: transform @transition;

    &--overlay {
      top: 5px;
      right: 5px;
      bottom: 5px;
      left: 5px;
      height: auto;

      padding: 20px 5px;

      background-color: rgba(148, 218, 190, 0.85);
      opacity: 0;
      visibility: hidden;
      transform: translateY(0);

      transition: opacity @transition;
    }
  }
  &__actions-inner {
    position: absolute;
    top: 50%;
    left: 10px;
    right: 10px;
    transform: translateY(-50%);
  }
  &__actions-link,
  &__actions-link:hover {
    color: #333333;
    font-size: 16px;
  }
  &__actions-link:hover {
    text-decoration: underline;
  }
  &__buttons {
    display: inline-block;
    vertical-align: middle;

    width: 22px;
    height: 22px;

    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;

    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  //&__reserve {
  //    background-image: url("/assets-configurator/themes/theme-ab/assets/reserve.png");
  //
  //  &:hover {
  //    background-image: url("/assets-configurator/themes/theme-ab/assets/reserve-active.png");
  //  }
  //}
  //&__download {
  //  background-image: url("/assets-configurator/themes/theme-ab/assets/download.png");
  //
  //  &:hover {
  //    background-image: url("/assets-configurator/themes/theme-ab/assets/download-active.png");
  //  }
  //}
  &__cover {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;

    height: 25px;

    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 5px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 30%, rgba(255, 255, 255, 0) 100%);

    transition: transform @transition;
  }
  &__line {
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;

    border: 0;
    background-color: #333;
    opacity: 0.3;
  }
  &__link {
    display: block;
  }

  &--row-column {
    flex-flow: column;

    .pictures-grid__item:hover {
      .pictures-grid__actions {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
      .pictures-grid__cover {
        display: none;
      }
    }
  }
}
.screen-xs-min({
  .pictures-grid {
    &--row-column {
      flex-flow: row;
    }
  }
});

.reactRoot,
.main-content {
  height: 100%;
  box-sizing: border-box;
}

.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.left-sidebar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.screen-md-min({
  .left-sidebar {
    top: 0;
    left: 15px;
    right: auto;
    bottom: auto;
  }
});

.sidebar-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  height: 46px;
  border-top: 1px solid #ececec;
  background-color: #f6f6f6;

  &__list,
  &__item,
  &__link {
    display: block;
    height: 100%;
  }
  &__list {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid #ececec;
  }
  &__item {
    width: calc(100% / 5);
    float: left;

    color: @font-color;
    border-left: 1px solid #ececec;

    &__wait-approve {
      background-color: #f6f6f6;
      cursor: not-allowed !important;
    }
    .icon {
      display: block;
      margin-bottom: 5px;
      width: 60px;
      height: 32px;
    }
    &:hover,
    &.is-active {
      color: #fff;
      background-color: #94dabe;
      .icon {
        fill: #fff;
      }
    }
    &.briefs-icon {
      &:hover,
      &.is-active {
        .sidebar-menu__link::before {
          background-position: 0 -25px;
        }
      }
    }
    &.showroom-icon {
      .sidebar-menu__link::before {
        background-position: -23px 0;
      }
      &:hover,
      &.is-active {
        .sidebar-menu__link::before {
          background-position: -23px -25px;
        }
      }
    }
    &.price-icon {
      .sidebar-menu__link::before {
        background-position: -96px 0;
      }
      &:hover,
      &.is-active {
        .sidebar-menu__link::before {
          background-position: -96px -25px;
        }
      }
    }
    &.basket-icon {
      .sidebar-menu__link::before {
        background-position: -46px 0;
      }
      &:hover,
      &.is-active {
        .sidebar-menu__link::before {
          background-position: -46px -25px;
        }
      }
    }
    &.settings-icon {
      .sidebar-menu__link::before {
        background-position: -70px 0;
      }
      &:hover,
      &.is-active {
        .sidebar-menu__link::before {
          background-position: -70px -24px;
        }
      }
    }
  }
  &__link {
    padding-top: 32px;
    color: inherit;
    text-align: center;
    position: relative;

    &:hover {
      color: inherit;
    }

    &::before {
      content: '';

      position: absolute;
      left: 50%;
      top: 8px;

      width: 25px;
      height: 25px;

      margin-left: -13px;

      //background: url("/assets-configurator/themes/theme-ab/assets/sidebar-icons.png") no-repeat;
      background-size: 120px 48px;
    }
  }
  &__caption {
    display: none;
  }
}
.screen-xs-min({
  .sidebar-menu {
    height: 75px;

    &__caption {
      display: inline-block;
    }
  }
});
.screen-sm-min({
  .sidebar-menu {
    height: 75px;

    &__item {
      &.briefs-icon {
        &:hover,
        &.is-active {
          .sidebar-menu__link::before {
            background-position: 0 -34px;
          }
        }
      }
      &.showroom-icon {
        .sidebar-menu__link::before {
          background-position: -33px 0;
        }
        &:hover,
        &.is-active {
          .sidebar-menu__link::before {
            background-position: -33px -34px;
          }
        }
      }
      &.price-icon {
        .sidebar-menu__link::before {
          background-position: -130px 0;
        }
        &:hover,
        &.is-active {
          .sidebar-menu__link::before {
            background-position: -130px -34px;
          }
        }
      }
      &.basket-icon {
        .sidebar-menu__link::before {
          background-position: -63px 0;
        }
        &:hover,
        &.is-active {
          .sidebar-menu__link::before {
            background-position: -63px -32px;
          }
        }
      }
      &.settings-icon {
        .sidebar-menu__link::before {
          background-position: -98px 0;
        }
        &:hover,
        &.is-active {
          .sidebar-menu__link::before {
            background-position: -98px -34px;
          }
        }
      }
    }
    &__link {
      padding-top: 42px;
      font-size: 14px;

      &::before {
        top: 12px;
        width: 30px;
        height: 30px;
        margin-left: -15px;
        background-size: 162px 64px;
      }
    }

  }
});
.screen-md-min({
  .sidebar-menu {
    position: relative;
    height: auto;
    border-top: 0;

    &__list,
    &__item,
    &__link {
      height: auto;
    }
    &__list {
      max-width: none;
      border-right: 0;
    }
    &__item {
      width: 100%;
      float: none;
      border-left: 0;
      border-top: 1px solid #ececec;
      position: relative;

      &:first-child {
        border-top: 0;
      }
    }
    &__link {
      width: 100px;
      height: 100px;
      padding-top: 63px;

      &::before {
        top: 31px;
      }
    }
  }
});
.screen-lg-min({
  .sidebar-menu {
    &__link {
      width: 135px;
      height: 120px;
      padding-top: 63px;

      &::before {
        top: 31px;
      }
    }
  }
});

.middle-content {
  border-top: 1px solid transparent;
}
