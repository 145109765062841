@import '~packages/theme-ab/semantic/site/globals/site.variables';

.modal.ui {
  font-size: 15px;
  font-family: @fontFamily;

  .modal__close {
    position: absolute;
    top: 20px;
    right: 17px;
    z-index: 2;

    width: 20px;
    height: 14px;

    cursor: pointer;
  }
  .modal__title {
    font-size: 30px;
    font-family: @fontFamily;
    margin-bottom: 10px;
  }

  .modal__header {
    font-family: @fontFamily;
    font-weight: 500;
  }
  .modal__body,
  .modal__footer,
  .modal__header {
    padding: 10px 20px;
  }

  &.modal--medium {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.screen-sm-min({
  .modal.ui {
    &.modal--medium {
      width: 570px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
});
