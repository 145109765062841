* {
  margin: 0;
  padding: 0;
}

body {
  font: normal @regular @font-size / @line-height @font-family;
  background: #f6f6f6;
}

.g-link {
  color: #999;

  &--white {
    color: #fff;
  }
  &--inherit {
    color: inherit;
  }
  &--underline {
    text-decoration: underline;
  }
}

.will-transition {
  transition: all @transition;
}

.h2 {
  font-size: 24px;
  text-align: center;
  font-weight: @regular;
  font-family: @fontFamily;
}

.screen-xs-min({ .h2 {
    font-size: 34px;
} });
