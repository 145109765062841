@import '~packages/theme-ab/semantic/site/globals/site.variables';

.header {
  height: 54px;
  margin-bottom: 26px;
  margin-left: 5px;
  margin-right: 15px;
  border-bottom: 1px solid #ececec;
  font-weight: 500;
  font-family: @fontFamily;

  &__user-block {
    float: right;
  }
}
.screen-sm-min({
  .header {
    height: 91px;
    margin-bottom: 28px;
    margin-left: 15px;
    margin-right: 15px;
  }
});

.user-block {
  margin: 10px 0;

  &__label,
  &__avatar {
    display: inline-block;
    vertical-align: middle;
  }
  &__label {
    margin-right: 4px;

    font-size: 17px;
    line-height: 22px;
    font-family: @fontFamily;
  }
  &__avatar {
    width: 33px;
    height: 33px;
  }
  &__avatar-image {
    max-width: 100%;
    height: auto;
  }
  &.ui.dropdown > .dropdown.icon {
    margin-left: 7px;
  }
}
.screen-sm-min({
  .user-block {
    margin: 24px 0;

    &__label {
      margin-right: 11px;
      font-size: 20px;
    }
    &__avatar {
      width: 42px;
      height: 42px;
    }
    &__avatar-image {
      max-width: 100%;
      height: auto;
    }
    &.ui.dropdown > .dropdown.icon {
      margin-left: 12px;
    }
  }
});
