@green-color: #30cb8a;
@regular: 400;
@fontFamily: Sailec, sans-serif;

.button {
  font-family: @fontFamily !important;
  font-weight: @regular !important;
  &.nowrap {
    white-space: nowrap;
  }
  &.green {
    font-family: @fontFamily;
    font-weight: @regular;
    color: #fff;
    background-color: @green-color;
    &:hover,
    &:focus {
      color: #fff;
      background-color: #6cc50d;
    }
    &.basic {
      color: #fff !important;
      box-shadow: 0 0 0 1px @green-color inset;

      &:hover,
      &.is-active {
        color: #fff !important;
        background-color: @green-color !important;
        box-shadow: 0 0 0 1px @green-color inset;
      }
    }
    &-solid {
      background: @green-color;
      color: #fff !important;
      &:hover {
        background: @green-color;
        color: #fff !important;
      }
    }
  }
  &.red {
    font-family: @fontFamily;
    font-weight: @regular;
    color: #fff;
    background-color: red;
    &:hover,
    &:focus {
      color: #fff;
      background-color: red;
    }
    &.basic {
      color: #fff !important;
      box-shadow: 0 0 0 1px red inset;

      &:hover,
      &.is-active {
        color: #fff !important;
        background-color: red !important;
        box-shadow: 0 0 0 1px red inset;
      }
    }
    &-solid {
      background: red;
      color: #fff !important;
      &:hover {
        background: red;
        color: #fff !important;
      }
    }
  }
  &.basic {
    background: none !important;
    &--grey {
      padding: 6px 12px 6px 12px !important;
      border: solid 1px !important;
    }
  }
  &.upload {
    background-color: #ececec;
    border-radius: 1px;
    font-size: 16px;
    font-weight: @regular;
    margin-bottom: 20px;
    white-space: nowrap;
  }
  &.fluid {
    margin: 0;
  }
  &.centered {
    display: flex;
    margin: 0 auto;
  }
  &.transparent {
    background: transparent;
    border: 1px solid @green-color;
    color: #fff !important;
    &:hover {
      background: @green-color;
    }
  }
}
