/**
 * @license
 * MyFonts Webfont Build ID 3890245, 2020-04-13T12:14:42-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Sailec-Bold by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/bold/
 *
 * Webfont: Sailec-Light by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/light/
 *
 * Webfont: Sailec-Medium by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/medium/
 *
 * Webfont: Sailec-Regular by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/regular/
 *
 * Webfont: Sailec-Thin by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/thin/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 2014 by Type Dynamic. All rights reserved.
 *
 * © 2020 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3b5c45');

@font-face {
  font-family: 'Sailec';
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url('./webFonts/Sailec/normal_normal_normal.woff2') format('woff2'),
    url('./webFonts/Sailec/normal_normal_normal.woff') format('woff');
}
@font-face {
  font-family: 'Sailec-Medium';
  src: url('./webFonts/Sailec-Medium/font.woff2') format('woff2'),
    url('./webFonts/Sailec-Medium/font.woff') format('woff');
}
@font-face {
  font-family: 'Sailec-Thin';
  src: url('./webFonts/Sailec-Thin/font.woff2') format('woff2'),
    url('./webFonts/Sailec-Thin/font.woff') format('woff');
}
@font-face {
  font-family: 'Sailec-Light';
  src: url('./webFonts/Sailec-Light/font.woff2') format('woff2'),
    url('./webFonts/Sailec-Light/font.woff') format('woff');
}
@font-face {
  font-family: 'Sailec';
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  src: url('./webFonts/Sailec/normal_normal_bold.woff2') format('woff2'),
    url('./webFonts/Sailec/normal_normal_bold.woff') format('woff');
}
