@import '~packages/theme-ab/semantic/site/globals/site.variables';

.three-dots {
  cursor: pointer;
  height: 31px;
  align-self: center;
  width: 24px;
  margin-left: 18px;
  margin-right: 8px;
  background-size: contain;
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 11;

  height: @header-height;
  font-size: 15px;

  background-color: transparent;
  transition: background-color 200ms ease-in 300ms;

  &__left,
  &__right {
    list-style: none;
  }
  &__left {
    float: left;
    margin-top: 12px;
  }
  &__right {
    float: right;
  }
  &__item {
    float: left;
    margin-right: 10px;
    cursor: pointer;

    &--buttons {
      display: none;
    }
  }
  &__link {
    font-family: @fontFamily;
    position: relative;

    &:hover {
      color: #fff;
    }

    &--vertical-fluid {
      display: block;
      margin-top: 9px;
    }
  }
  &__logo {
    display: inline-block;
    margin-left: 15px;
    :global(svg) {
      width: 100px;
      height: 2.1em;
      fill: #fff;
    }
  }
  &__site-search {
    display: none;

    .form {
      position: relative;
    }
    .form__dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 8;

      display: none;

      padding-top: 8px;
      padding-bottom: 8px;

      background-color: #ffffff;
      text-align: left;
      border-radius: 2px;
      box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
    }
  }
  &__dropdown {
    display: flex !important;
    height: @header-height;
    a {
      font-size: 14px;
      font-weight: @bold;
      color: @font-color;
    }

    span {
      font-size: 14px;
      font-weight: @bold;
      color: @font-color;
    }
  }
  &__icon-shop.icon {
    font-size: 26px;

    .ui.floating.label {
      top: -4px;
      left: 110%;
    }
  }

  &.nav--white-on-black {
    -webkit-transition: background-color 200ms ease-in;
    transition: background-color 200ms ease-in;
    background-color: #111;
  }
}
.screen-sm-min({
  .nav {
    &__left {
      width: 75%;
    }
    &__site-search {
      display: block;
      width: 29%;
      margin-left: 20px;
      margin-right: 20px;

      .form__field {
        position: relative;
      }

      div {
        width: 100%;
      }

      input[type='text'] {
        height: 33px;
        padding-left: 30px;

        line-height: 1.5;
        color: #fff;

        background-color: #585858;
        border: 0;
        border-radius: 3px !important;
        transition: all @transition;

        .placeholder({
          color: #fff
        });
      }

    }
    &__item {
      &--buttons {
        display: block;
      }
    }
  }
});

.suggestion {
  &__item {
    padding: 0 12px;

    font-size: 1.072rem;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.8);

    overflow: hidden;
    cursor: pointer;
    text-overflow: ellipsis;

    &--active {
      background-color: #f2f2f2;
    }
  }
}

.nav-group-border {
  a {
    border: 1px solid #30cb8a !important;
    padding: 10px !important;
  }
  a:last-child {
    border-left-width: 0 !important;
  }
}
