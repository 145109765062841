@import '~packages/theme-ab/semantic/site/globals/site.variables';
.button {
  font-family: @fontFamily !important;
  font-weight: 500 !important;

  &.button-trigger {
    width: 100%;
    position: relative;
    text-align: left;

    background-color: #e0e1e2 !important;
    color: rgba(0, 0, 0, 0.8) !important;
    font-family: @fontFamily !important;

    i {
      background-color: #e0e1e2 !important;
    }

    .button-trigger__icon {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -7px;

      width: 13px;
      height: 9px;
    }
    .button-trigger__label {
      &.is-hidden {
        display: none;
      }
    }
    .button-trigger__input {
      visibility: hidden;
      position: absolute;

      &:checked {
        ~ .button-trigger__label {
          &.is-hidden {
            display: block;
          }
          &.is-visible {
            display: none;
          }
        }

        ~ .button-trigger__icon {
          svg {
            display: none;
          }

          &::after {
            display: block;
            content: '';
            width: 13px;
            height: 2px;
            background-color: #333;
            position: absolute;
            top: 6px;
          }
        }
      }
    }
  }

  &.custom-green {
    font-family: @fontFamily;
    font-weight: 500 !important;
    color: #fff;
    background-color: #30cb8a;

    &:hover,
    &:focus {
      color: #fff;
      background-color: #6cc50d;
    }

    &.basic {
      color: #fff !important;
      box-shadow: 0px 0px 0px 1px #30cb8a inset;

      &:hover,
      &.is-active {
        color: #fff !important;
        background-color: #30cb8a !important;
        box-shadow: 0px 0px 0px 1px #30cb8a inset;
      }
    }
  }
  &.select-photo__btn {
    background-color: #ececec;
    border-radius: 1px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    white-space: nowrap;
  }
}

.screen-sm-min({ .button {
    &.button-trigger {
        width: 200px;
    }
} });

.ui.star.rating .icon {
  color: rgba(255, 255, 255, 0.55);
}
