@fontFamily: Sailec, sans-serif;

.modal.ui {
  font-size: 15px;
  font-family: @fontFamily;
  &.modal--dimmed {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    will-change: unset !important;
  }
  .modal__icon-close {
    position: absolute;
    top: 20px;
    right: 17px;
    z-index: 2;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &--dimmed {
      svg {
        path {
          stroke: lightgrey;
          stroke-width: 2px;
        }
      }
    }
  }

  .modal__body,
  .modal__footer,
  .modal__header {
    padding: 0 24px;
  }

  .modal__content {
    padding-top: 0;
  }
  .modal__header {
    margin: 20px 0 20px 0;
  }
  .modal__footer {
    margin: 20px 0;
  }
  .modal__title {
    font-size: 30px;
    font-family: @fontFamily;
    margin-bottom: 10px;
  }
}
