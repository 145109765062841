@import '~packages/components/styles/export';

.loadMore {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.protected {
  pointer-events: none;
}
